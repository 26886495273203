import React from 'react'
import { connect } from 'react-redux'
import { Heading, Link, Box, Text } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import FlowLayout from '../layouts/FlowLayout'
import PartiesForm from '../forms/PartiesForm'
import { addData } from '../../actions/session'

const Parties = ({ addData }) => {
    const history = useHistory()

    return (
        <FlowLayout title="New Claim" progress={75}>
            <Heading sx={{ fontSize: 2 }}>People involved</Heading>
            <Box sx={{ my: 2 }}>
                <Text>
                    Please add any other people involved in the incident
                    excluding yourself.
                </Text>
            </Box>
            <PartiesForm
                onSubmit={async (values) => {
                    await addData(values)

                    history.push('/documents')
                }}
            />
            <Box sx={{ my: 3 }}>
                <Link to="/police" sx={{ variant: 'links.buttonOutline' }}>
                    Back
                </Link>
            </Box>
        </FlowLayout>
    )
}

const mapDispatchToProps = {
    addData
}

export default connect(null, mapDispatchToProps)(Parties)
