import { API, graphqlOperation } from 'aws-amplify'
import { customAlphabet } from 'nanoid'
import * as actions from './index'
import { convertEmptyValues } from '../utils/dynamodb'

const generateClaimId = customAlphabet('0123456789ABCDEFGHJKLMNPQRTUVWXYZ', 10)

// addClaim
const addClaimRequest = (data) => ({
    type: actions.ADD_CLAIM_REQUEST,
    data
})
const addClaimSuccess = (item) => ({
    type: actions.ADD_CLAIM_SUCCESS,
    item
})
const addClaimFailed = (error) => ({
    type: actions.ADD_CLAIM_FAILED,
    error
})

const addClaimQuery = /* GraphQL */ `
    mutation AddClaim($input: ClaimInput!) {
        addClaim(input: $input) {
            id
            status
            createdAt
        }
    }
`

export const addClaim = () => async (dispatch, getState) => {
    const {
        session: {
            policyId,
            data: { lossCodes, documents, ...data }
        }
    } = getState()

    const input = convertEmptyValues({
        id: generateClaimId(),
        policyId,
        lossCodes,
        documents,
        data
    })

    // convert string bools to real bools
    Object.keys(input).forEach((k) => {
        if (['true', 'false'].includes(input[k])) {
            input[k] = input[k] === 'true'
        }
    })

    input.data = JSON.stringify(input.data)

    dispatch(addClaimRequest(input))

    try {
        const response = await API.graphql(
            graphqlOperation(addClaimQuery, { input })
        )

        dispatch(addClaimSuccess(response.data.addClaim))

        return response.data.addClaim
    } catch (e) {
        dispatch(addClaimFailed(e.message))
        throw e
    }
}
