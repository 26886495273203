import React from 'react'
import { connect } from 'react-redux'
import { Heading, Link, Box } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import FlowLayout from '../layouts/FlowLayout'
import ConfirmForm from '../forms/ConfirmForm'
import { addClaim } from '../../actions/claim'

const Review = ({ addClaim }) => {
    const history = useHistory()

    return (
        <FlowLayout title="New Claim" progress={90}>
            <Heading sx={{ fontSize: 2 }}>Review</Heading>
            <ConfirmForm
                onSubmit={async () => {
                    await addClaim()

                    history.push('/done')
                }}
            />
            <Box sx={{ my: 3 }}>
                <Link to="/documents" sx={{ variant: 'links.buttonOutline' }}>
                    Back
                </Link>
            </Box>
        </FlowLayout>
    )
}

const mapDispatchToProps = {
    addClaim
}

export default connect(null, mapDispatchToProps)(Review)
