import { combineReducers } from 'redux'
import auth from './auth'
import session from './session'
import product from './product'
import policy from './policy'
import claim from './claim'
import upload from './upload'

const reducer = combineReducers({
    auth,
    session,
    product,
    policy,
    claim,
    upload
})

export default reducer
