import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { IsAuthenticated, ProtectedRoute } from '../hooks'

import NewClaim from '../views/NewClaim'
import Type from '../views/Type'
import DateOccurred from '../views/DateOccurred'
import Location from '../views/Location'
import Description from '../views/Description'
import Police from '../views/Police'
import Parties from '../views/Parties'
import Documents from '../views/Documents'
import Review from '../views/Review'
import Done from '../views/Done'
import NotFound from '../views/NotFound'
import withTracker from '../../utils/withTracker'

const Routes = () => (
    <IsAuthenticated>
        <Switch>
            <ProtectedRoute
                path="/start/:id"
                component={withTracker(NewClaim)}
            />
            <ProtectedRoute path="/type" component={withTracker(Type)} />
            <ProtectedRoute
                path="/date"
                component={withTracker(DateOccurred)}
            />
            <ProtectedRoute
                path="/location"
                component={withTracker(Location)}
            />
            <ProtectedRoute
                path="/description"
                component={withTracker(Description)}
            />
            <ProtectedRoute path="/police" component={withTracker(Police)} />
            <ProtectedRoute path="/parties" component={withTracker(Parties)} />
            <ProtectedRoute
                path="/documents"
                component={withTracker(Documents)}
            />
            <ProtectedRoute path="/review" component={withTracker(Review)} />
            <ProtectedRoute path="/done" component={withTracker(Done)} />
            <Route component={withTracker(NotFound)} />
        </Switch>
    </IsAuthenticated>
)

export default Routes
