import { theme } from '@sharecover-co/ui'
import merge from 'lodash.merge'

const base = {
    buttons: {
        icon: {
            border: 0,
            bg: 'transparent',
            ':hover,:focus,.active': {
                bg: 'transparent',
                color: 'primary'
            },
            p: 0,
            fontSize: 24,
            color: 'secondary',
            textAlign: 'center',
            lineHeight: 1,
            cursor: 'pointer',
            transition: 'all 0.2s ease-out'
        }
    }
}

export default merge(theme, base)
