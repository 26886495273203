import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Textarea, Submit } from '@sharecover-co/ui'
import * as Yup from 'yup'
import { Form } from 'formik'
import FormManager from './FormManager'

const schema = Yup.object().shape({
    description: Yup.string().required('Required')
})

const DescriptionForm = (props) => (
    <FormManager
        useSession
        validationSchema={schema}
        initialValues={{
            description: ''
        }}
        {...props}
    >
        {({ status: { error } }) => (
            <Form>
                {error && <Alert>{error}</Alert>}
                <Textarea name="description" label="Description" required />
                <Submit label="Next" />
            </Form>
        )}
    </FormManager>
)

DescriptionForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default DescriptionForm
