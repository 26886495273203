import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
    Heading,
    Link,
    Progress,
    Grid,
    Box,
    Button,
    Icon
} from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import FlowLayout from '../layouts/FlowLayout'
import { addData } from '../../actions/session'
import { upload, deleteUpload } from '../../actions/upload'
import S3Image from '../base/S3Image'

const colors = {
    UPLOAD_REQUEST: null,
    UPLOAD_PROGRESS: null,
    UPLOAD_SUCCESS: '#0bc021',
    UPLOAD_FAILED: 'error'
}

const Documents = ({ upload, files, deleteUpload, addData }) => {
    const [requiredError, showRequiredError] = useState(false)
    const history = useHistory()
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (items) => {
            showRequiredError(false)
            items.map(upload)
        }
    })

    const onSubmit = () => {
        if (files.length === 0) {
            showRequiredError(true)

            return
        }

        addData({
            documents: files.map((f) => ({
                key: f.key,
                name: f.file.path
            }))
        })

        history.push('/review')
    }

    const isPending = !!files.find(({ status }) => status !== 'UPLOAD_SUCCESS')

    return (
        <FlowLayout title="New Claim" progress={82}>
            <Heading sx={{ fontSize: 2 }}>
                Please attach photos/evidence of damage.
            </Heading>
            <Box
                {...getRootProps({ className: 'dropzone' })}
                sx={{
                    border: '2px dashed',
                    borderColor: 'lightGrey',
                    textAlign: 'center',
                    p: 2,
                    my: 2
                }}
            >
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </Box>
            {requiredError && (
                <div style={{ color: 'red', marginTop: '.5rem' }}>
                    You must upload at least one document.
                </div>
            )}
            {files.length > 0 && (
                <Grid
                    gap={2}
                    columns="repeat(auto-fill,minmax(100px,1fr))"
                    sx={{ my: 4 }}
                >
                    {files.map((file) => (
                        <Box
                            key={file.id}
                            sx={{
                                boxShadow: '0 2px 4px 0 rgba(0,0,0,0.24)',
                                height: 100,
                                overflow: 'hidden',
                                position: 'relative'
                            }}
                        >
                            <Button
                                onClick={() => deleteUpload(file.id)}
                                variant="buttons.icon"
                                sx={{
                                    position: 'absolute',
                                    right: '10px',
                                    bottom: '5px'
                                }}
                            >
                                <Icon icon={['fal', 'times']} />
                            </Button>
                            <Progress
                                max={1}
                                value={file.progress / 100}
                                sx={{
                                    height: '4px !important',
                                    color: colors[file.status]
                                }}
                            />
                            {file.progress === 100 && (
                                <S3Image src={file.key} />
                            )}
                        </Box>
                    ))}
                </Grid>
            )}
            <Box sx={{ mt: 5 }}>
                <Button onClick={onSubmit} disabled={isPending}>
                    Next
                </Button>
            </Box>
            <Box sx={{ my: 3 }}>
                <Link to="/parties" sx={{ variant: 'links.buttonOutline' }}>
                    Back
                </Link>
            </Box>
        </FlowLayout>
    )
}

const mapStateToProps = (state) => ({
    files: Object.values(state.upload)
})

const mapDispatchToProps = {
    upload,
    deleteUpload,
    addData
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents)
