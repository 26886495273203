export const convertEmptyValues = (Item) => {
    return JSON.parse(
        JSON.stringify(Item, (key, value) => {
            if (value === '' || value === null || value === undefined) {
                return undefined
            }
            return value
        })
    )
}
