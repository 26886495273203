import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Checkbox, Submit } from '@sharecover-co/ui'
import * as Yup from 'yup'
import { Form } from 'formik'
import FormManager from './FormManager'

const schema = Yup.object().shape({
    agree: Yup.string()
        .required('Required')
        .oneOf(['y'], 'You must agree to the terms.')
})

const ConfirmForm = (props) => (
    <FormManager
        validationSchema={schema}
        initialValues={{ agree: '' }}
        {...props}
    >
        {({ status: { error } }) => (
            <Form>
                {error && <Alert>{error}</Alert>}
                <Checkbox
                    name="agree"
                    label="Do you agree?"
                    choices={{
                        y: 'Yes, I agree that the details provided are true.'
                    }}
                    required
                />
                <Submit label="Submit Claim" />
            </Form>
        )}
    </FormManager>
)

ConfirmForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default ConfirmForm
