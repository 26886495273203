import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash.get'
import { Heading } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import FlowLayout from '../layouts/FlowLayout'
import TypeForm from '../forms/TypeForm'
import { addData } from '../../actions/session'

const Type = ({ lossCodes, addData }) => {
    const history = useHistory()

    return (
        <FlowLayout title="New Claim" progress={5}>
            <Heading sx={{ fontSize: 2 }}>Type of Claim</Heading>
            <TypeForm
                lossCodes={lossCodes}
                onSubmit={async (values) => {
                    await addData(values)

                    history.push('/date')
                }}
            />
        </FlowLayout>
    )
}

const mapStateToProps = (state) => ({
    lossCodes: get(state, 'product.product.claimFlow.lossCodes', [])
})

const mapDispatchToProps = {
    addData
}

export default connect(mapStateToProps, mapDispatchToProps)(Type)
