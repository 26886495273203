import { API, graphqlOperation } from 'aws-amplify'
import * as actions from './index'
// import * as queries from '../graphql/queries'

// getPolicy
const getPolicyRequest = (id, accessKey) => ({
    type: actions.GET_POLICY_REQUEST,
    id,
    accessKey
})
const getPolicySuccess = (item) => ({
    type: actions.GET_POLICY_SUCCESS,
    item
})
const getPolicyFailed = (error) => ({
    type: actions.GET_POLICY_FAILED,
    error
})

const getPolicyQuery = /* GraphQL */ `
    query GetPolicy($id: ID!) {
        getPolicy(id: $id) {
            id
            userId
            productId
            product {
                id
                name
                description
                claimFlowId
                claimFlow {
                    id
                    name
                    url
                    fields {
                        name
                        path
                        format
                        config
                    }
                    lossCodes {
                        name
                        lossCodes {
                            name
                            lossCodes {
                                name
                            }
                        }
                    }
                    createdAt
                    updatedAt
                }
                premiumPriceId
                unitPriceId
                policyIdPrefix
                policyDescriptionPath
                documents {
                    name
                    key
                    url
                }
                createdAt
                updatedAt
            }
            data
            description
            startDate
            startTime
            endDate
            endTime
            cancelledAt
            createdAt
            updatedAt
        }
    }
`

export const getPolicy = (id, accessKey) => async (dispatch) => {
    dispatch(getPolicyRequest(id, accessKey))

    try {
        const response = await API.graphql(
            graphqlOperation(getPolicyQuery, { id, accessKey })
        )

        if (!response.data.getPolicy) {
            throw new Error('No policy found.')
        }

        dispatch(getPolicySuccess(response.data.getPolicy))
    } catch (e) {
        dispatch(getPolicyFailed(e.message))
        throw e
    }
}
