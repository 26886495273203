// Auth
export const AUTHED = 'AUTHED'
export const UNAUTHENTICATED = 'UNAUTHENTICATED'
export const LOGOUT = 'LOGOUT'

// Session
export const NEW_SESSION = 'NEW_SESSION'
export const ADD_DATA = 'ADD_DATA'

// Uploads
export const UPLOAD_REQUEST = 'UPLOAD_REQUEST'
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const UPLOAD_FAILED = 'UPLOAD_FAILED'

export const DELETE_UPLOAD_REQUEST = 'DELETE_UPLOAD_REQUEST'
export const DELETE_UPLOAD_SUCCESS = 'DELETE_UPLOAD_SUCCESS'
export const DELETE_UPLOAD_FAILED = 'DELETE_UPLOAD_FAILED'

// Product
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED'

// Policy
export const GET_POLICY_REQUEST = 'GET_POLICY_REQUEST'
export const GET_POLICY_SUCCESS = 'GET_POLICY_SUCCESS'
export const GET_POLICY_FAILED = 'GET_POLICY_FAILED'

export const POLICY_REQUEST = 'POLICY_REQUEST'
export const POLICY_SUCCESS = 'POLICY_SUCCESS'
export const POLICY_FAILED = 'POLICY_FAILED'

// Claim
export const ADD_CLAIM_REQUEST = 'ADD_CLAIM_REQUEST'
export const ADD_CLAIM_SUCCESS = 'ADD_CLAIM_SUCCESS'
export const ADD_CLAIM_FAILED = 'ADD_CLAIM_FAILED'
