import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { newSession } from '../../actions/session'
import { getPolicy } from '../../actions/policy'
import FlowLayout from '../layouts/FlowLayout'

const NewClaim = ({
    newSession,
    getPolicy,
    match: {
        params: { id: policyId }
    }
}) => {
    const [ready, setIsReady] = useState(null)

    useEffect(() => {
        const start = async () => {
            try {
                await getPolicy(policyId)
                setIsReady(true)
            } catch (e) {
                setIsReady(e.message)
            }
        }

        newSession({ policyId })
        start()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyId])

    // done
    if (ready === true) {
        return <Redirect to="/type" />
    }

    // loading
    if (ready === null) {
        return <FlowLayout title="New Claim" progress={0} loading />
    }

    // failed
    return (
        <FlowLayout title="New Claim" progress={0}>
            {ready}
        </FlowLayout>
    )
}

const mapDispatchToProps = {
    newSession,
    getPolicy
}

export default connect(null, mapDispatchToProps)(NewClaim)
