import React from 'react'
import { connect } from 'react-redux'
import { Flex, Box, Heading, Text, Tag, parseFields } from '@sharecover-co/ui'
import get from 'lodash.get'

const Aside = ({ session, fields, policy, claim }) => (
    <Box variant="boxed">
        {policy && (
            <Box sx={{ mb: 4 }}>
                {!claim && <Tag>Policy {policy.id}</Tag>}
                {claim && <Tag>Claim {claim.id}</Tag>}
                <Heading as="h5" sx={{ my: 1 }}>
                    {policy.product.name}
                </Heading>
                <Text sx={{ mb: 2 }}>{policy.product.description}</Text>
            </Box>
        )}
        {parseFields(session, fields).map(([key, val]) => (
            <Flex
                key={key}
                sx={{
                    borderBottom: '2px solid',
                    borderBottomColor: 'lightGrey',
                    mt: 1,
                    pb: 1,
                    ':last-child': {
                        borderBottom: 'none',
                        pb: 0
                    },
                    '> strong': {
                        flex: 1
                    },
                    '> span': {
                        textAlign: 'right',
                        whiteSpace: 'pre-line'
                    }
                }}
            >
                <strong>{key}</strong>
                <span>{val || '-'}</span>
            </Flex>
        ))}
    </Box>
)

const mapStateToProps = (state) => ({
    session: get(state, 'session.data', {}),
    fields: get(state, 'product.product.claimFlow.fields', []),
    policy: get(state, 'policy.policy'),
    claim: get(state, 'claim.claim')
})

export default connect(mapStateToProps)(Aside)
