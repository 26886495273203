import React from 'react'
import { connect } from 'react-redux'
import { Heading, Link, Box } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import FlowLayout from '../layouts/FlowLayout'
import LocationForm from '../forms/LocationForm'
import { addData } from '../../actions/session'

const Location = ({ addData, policy }) => {
    const history = useHistory()
    const data = JSON.parse(policy.data)

    return (
        <FlowLayout title="New Claim" progress={30}>
            <Heading sx={{ fontSize: 2 }}>
                Where did the incident occur?
            </Heading>
            <LocationForm
                address={data?.address}
                onSubmit={async (values) => {
                    await addData(values)

                    history.push('/description')
                }}
            />
            <Box sx={{ my: 3 }}>
                <Link to="/date" sx={{ variant: 'links.buttonOutline' }}>
                    Back
                </Link>
            </Box>
        </FlowLayout>
    )
}

const mapStateToProps = (state) => ({
    ...state.policy
})

const mapDispatchToProps = {
    addData
}

export default connect(mapStateToProps, mapDispatchToProps)(Location)
