import * as actions from '../actions'

const INITIAL_STATE = {
    loading: false,
    policy: null,
    error: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.NEW_SESSION:
            return { ...INITIAL_STATE }

        case actions.GET_POLICY_REQUEST:
            return { ...state, loading: true }

        case actions.GET_POLICY_SUCCESS:
            const policy = action.item

            return {
                ...state,
                loading: false,
                policy,
                error: null
            }

        case actions.GET_POLICY_FAILED:
            return { ...state, loading: false, error: action.error }

        default:
            return state
    }
}

export default reducer
