import { Storage } from 'aws-amplify'
import { nanoid } from 'nanoid'
import * as actions from './index'

// upload
export const upload = (file) => async (dispatch) => {
    const id = nanoid()
    const ext = file.name.split('.').pop()
    const key = `${id}.${ext}`

    dispatch({
        type: actions.UPLOAD_REQUEST,
        id,
        key,
        file
    })

    try {
        await Storage.vault.put(key, file, {
            contentType: file.type,
            progressCallback: (progress) => {
                dispatch({
                    type: actions.UPLOAD_PROGRESS,
                    id,
                    progress: Math.round(
                        (progress.loaded / progress.total) * 100
                    )
                })
            }
        })

        dispatch({
            type: actions.UPLOAD_SUCCESS,
            id
        })

        return { id, key }
    } catch (e) {
        dispatch({
            type: actions.UPLOAD_FAILED,
            id,
            error: e.message
        })
        throw e
    }
}

// deleteUpload
export const deleteUpload = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: actions.DELETE_UPLOAD_REQUEST,
            id
        })

        const file = getState().upload[id]

        await Storage.vault.remove(file.key)

        dispatch({
            type: actions.DELETE_UPLOAD_SUCCESS,
            id
        })
    } catch (e) {
        dispatch({
            type: actions.DELETE_UPLOAD_FAILED,
            id,
            error: e.message
        })
        throw e
    }
}
