import React from 'react'
import { Box } from '@sharecover-co/ui'

const NotFound = () => {
    return (
        <Box variant="fixedResponsive">
            <h3>Sorry, page not found!</h3>
        </Box>
    )
}

export default NotFound
