import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Field, Radio, Date, Submit } from '@sharecover-co/ui'
import * as Yup from 'yup'
import { Form } from 'formik'
import FormManager from './FormManager'

const schema = Yup.object().shape({
    dateOfIncident: Yup.string().required('Required'),
    timeOfIncident: Yup.string(),
    insurerPresent: Yup.bool().required('Required'),
    hasWitnesses: Yup.bool().required('Required')
})

const DateForm = (props) => (
    <FormManager
        useSession
        validationSchema={schema}
        initialValues={{
            dateOfIncident: '',
            timeOfIncident: '',
            insurerPresent: '',
            hasWitnesses: ''
        }}
        {...props}
    >
        {({ status: { error } }) => (
            <Form>
                {error && <Alert>{error}</Alert>}
                <Box
                    sx={{
                        '> * > div': {
                            display: 'flex'
                        }
                    }}
                >
                    <Date
                        name="dateOfIncident"
                        label="Date of incident"
                        required
                        allowPast
                        allowFuture={false}
                    />
                </Box>
                <Field
                    name="timeOfIncident"
                    label="Approximate incident time"
                    type="time"
                />
                <Radio
                    name="insurerPresent"
                    label="Were you there at the incident?"
                    choices={{
                        [true]: 'Yes',
                        [false]: 'No'
                    }}
                    required
                />
                <Radio
                    name="hasWitnesses"
                    label="Were there any witnesses?"
                    choices={{
                        [true]: 'Yes',
                        [false]: 'No'
                    }}
                    required
                />
                <Submit label="Next" />
            </Form>
        )}
    </FormManager>
)

DateForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default DateForm
