import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Select, Submit } from '@sharecover-co/ui'
import * as Yup from 'yup'
import { Form, FieldArray } from 'formik'
import FormManager from './FormManager'

const convertToSelectChoices = (values) =>
    values.reduce((p, c) => {
        p[c.name] = c.name
        return p
    }, {})

const schema = Yup.object().shape({
    lossCodes: Yup.array().of(Yup.string().required('Required')).min(1)
})

const TypeForm = ({ lossCodes, ...props }) => (
    <FormManager
        useSession
        validationSchema={schema}
        initialValues={{
            lossCodes: ['']
        }}
        {...props}
    >
        {({ values, status: { error }, setFieldValue }) => (
            <Form>
                {error && <Alert>{error}</Alert>}
                <FieldArray
                    name="lossCodes"
                    render={(arrayHelpers) => {
                        let choices = lossCodes

                        return values.lossCodes.map((value, index) => {
                            const currentChoices = [...choices]

                            const onChange = (e) => {
                                arrayHelpers.pop('')
                                const newValue = e.target.value

                                // set new value
                                setFieldValue(`lossCodes.${index}`, newValue)

                                // find choice
                                const choice = newValue
                                    ? currentChoices.find(
                                          (t) => t.name === newValue
                                      )
                                    : null

                                // if choice has its own loss codes
                                if (choice && choice.lossCodes) {
                                    // add a new value to render
                                    arrayHelpers.push('')
                                } else {
                                    // otherwise ensure the next value does not exist
                                    for (
                                        let i = index + 1;
                                        i < values.lossCodes.length;
                                        i++
                                    ) {
                                        arrayHelpers.remove(index + 1)
                                    }
                                }
                            }

                            // prep choices for next iteration
                            choices = value
                                ? (
                                      currentChoices.find(
                                          (t) => t.name === value
                                      ) || {}
                                  ).lossCodes
                                : null

                            return (
                                <div key={index}>
                                    <Select
                                        name={`lossCodes.${index}`}
                                        label={`Type #${index + 1}`}
                                        choices={convertToSelectChoices(
                                            currentChoices
                                        )}
                                        onChange={onChange}
                                        required
                                    />
                                </div>
                            )
                        })
                    }}
                />

                <Submit label="Next" />
            </Form>
        )}
    </FormManager>
)

TypeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default TypeForm
