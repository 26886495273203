import React from 'react'
import { connect } from 'react-redux'
import { Heading, Link, Box, Text } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import FlowLayout from '../layouts/FlowLayout'
import PoliceForm from '../forms/PoliceForm'
import { addData } from '../../actions/session'

const Police = ({ addData }) => {
    const history = useHistory()

    return (
        <FlowLayout title="New Claim" progress={60}>
            <Heading sx={{ fontSize: 2 }}>
                Police incident report number
            </Heading>
            <Text>This is required for theft and malicious damage claims.</Text>
            <PoliceForm
                onSubmit={async (values) => {
                    await addData(values)

                    history.push('/parties')
                }}
            />
            <Box sx={{ my: 3 }}>
                <Link to="/description" sx={{ variant: 'links.buttonOutline' }}>
                    Back
                </Link>
            </Box>
        </FlowLayout>
    )
}

const mapDispatchToProps = {
    addData
}

export default connect(null, mapDispatchToProps)(Police)
