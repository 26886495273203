import React from 'react'
import { connect } from 'react-redux'
import { Heading, Text, Box, Link, Tag } from '@sharecover-co/ui'
import FlowLayout from '../layouts/FlowLayout'

const Done = ({ claim }) => {
    const nextUrl = `${process.env.REACT_APP_ACCOUNTS_URL}/claim/${claim.id}`

    return (
        <FlowLayout
            title="Success"
            progress={100}
            menu={<Link href={nextUrl}>View Claim</Link>}
        >
            <Heading sx={{ mb: 1, fontSize: 2 }}>You're all done!</Heading>
            <Text>
                Your ShareCover claim number is <Tag>{claim.id}</Tag>. You can
                see all the details of your claim in your ShareCover dashboard.
            </Text>
            <Box sx={{ my: 3 }}>
                <Link href={nextUrl} sx={{ variant: 'links.button' }}>
                    View Claim
                </Link>
            </Box>
        </FlowLayout>
    )
}

const mapStateToProps = (state) => ({
    ...state.claim
})

export default connect(mapStateToProps)(Done)
