import React, { useState, useEffect } from 'react'
import { Storage } from 'aws-amplify'
import { Image } from '@sharecover-co/ui'

const S3Image = ({ src }) => {
    const [url, setUrl] = useState()

    const fetchData = async () => {
        const signedUrl = await Storage.vault.get(src, { expires: 60 })
        setTimeout(() => setUrl(signedUrl), 300)
    }

    useEffect(() => {
        if (src) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src])

    return url ? <Image src={url} /> : null
}

export default S3Image
