import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Submit } from '@sharecover-co/ui'
import * as Yup from 'yup'
import { Form } from 'formik'
import FormManager from './FormManager'
import Address from '../../utils/Address'
const schema = Yup.object().shape({
    address: Yup.object()
        .shape({
            formatted: Yup.string().required('Required'),
            isManualAddress: Yup.boolean(),

            streetAddress: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string().required('Required')
            }),
            suiteAddress: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string()
            }),
            town: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string()
            }),
            city: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string().required('Required')
            }),
            region: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string()
            }),
            postcode: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string().required('Required')
            }),
            country: Yup.string().when('isManualAddress', {
                is: true,
                then: Yup.string().required('Required')
            })
        })
        .required('Required')
})

const LocationForm = ({ address, ...rest }) => (
    <FormManager
        useSession
        validationSchema={schema}
        initialValues={{
            address: address || {
                formatted: '',
                isManualAddress: false,

                suiteAddress: '',
                streetAddress: '',
                town: '',
                city: '',
                region: '',
                postcode: '',
                country: '',

                placeId: '',
                lat: '',
                lng: ''
            }
        }}
        {...rest}
    >
        {({ status: { error } }) => (
            <Form>
                {error && <Alert>{error}</Alert>}
                <Address id="address" name="address.formatted" required />
                <Submit label="Next" />
            </Form>
        )}
    </FormManager>
)

LocationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default LocationForm
