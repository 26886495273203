import React from 'react'
import { connect } from 'react-redux'
import { Heading, Link, Box } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import FlowLayout from '../layouts/FlowLayout'
import DescriptionForm from '../forms/DescriptionForm'
import { addData } from '../../actions/session'

const Description = ({ addData }) => {
    const history = useHistory()

    return (
        <FlowLayout title="New Claim" progress={45}>
            <Heading sx={{ fontSize: 2 }}>Describe the incident</Heading>
            <DescriptionForm
                onSubmit={async (values) => {
                    await addData(values)

                    history.push('/police')
                }}
            />
            <Box sx={{ my: 3 }}>
                <Link to="/location" sx={{ variant: 'links.buttonOutline' }}>
                    Back
                </Link>
            </Box>
        </FlowLayout>
    )
}

const mapDispatchToProps = {
    addData
}

export default connect(null, mapDispatchToProps)(Description)
