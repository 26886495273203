import get from 'lodash.get'
import * as actions from '../actions'

const INITIAL_STATE = {
    loading: false,
    product: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.NEW_SESSION:
            return { ...INITIAL_STATE }

        case actions.GET_PRODUCT_REQUEST:
            return { ...INITIAL_STATE, loading: true }

        case actions.GET_PRODUCT_SUCCESS:
            return { ...INITIAL_STATE, product: action.item }

        case actions.GET_POLICY_SUCCESS:
            return get(action, 'item.product')
                ? { ...INITIAL_STATE, product: action.item.product }
                : { ...state }

        default:
            return state
    }
}

export default reducer
