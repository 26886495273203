import * as actions from '../actions'

const INITIAL_STATE = {
    auth: null,
    id: null,
    credentials: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.AUTHED:
            return { auth: true, id: action.id }

        case actions.UNAUTHENTICATED:
        case actions.LOGOUT:
            return { ...INITIAL_STATE, auth: false }

        default:
            return state
    }
}

export default reducer
