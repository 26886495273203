import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Routes from './Routes'
import { isAuthenticated } from '../../actions/auth'

class App extends Component {
    async componentDidMount() {
        this.props.isAuthenticated()
    }

    render() {
        return <Routes />
    }
}

const mapDispatchToProps = {
    isAuthenticated
}

export default withRouter(connect(null, mapDispatchToProps)(App))
