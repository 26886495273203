import React from 'react'
import { useModal } from 'react-modal-hook'
import { Flex, Heading, Button, Link, Modal } from '@sharecover-co/ui'

const ExitModal = () => {
    const [showModal, hideModal] = useModal(() => (
        <Modal onRequestClose={hideModal}>
            <Heading as="h4">Are you sure?</Heading>
            <Flex sx={{ mt: 3, mx: -1 }}>
                <Link
                    href={process.env.REACT_APP_ACCOUNTS_URL}
                    variant="button"
                    sx={{ flex: 1, minWidth: '0', mx: 1 }}
                >
                    Exit
                </Link>
                <Button
                    onClick={hideModal}
                    variant="buttons.outline"
                    sx={{ flex: 1, minWidth: '0', mx: 1 }}
                >
                    Cancel
                </Button>
            </Flex>
        </Modal>
    ))

    return (
        <Button
            variant="text"
            type="button"
            onClick={showModal}
            sx={{ py: 0, my: 0, lineHeight: 1 }}
        >
            Exit
        </Button>
    )
}

export default ExitModal
