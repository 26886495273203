import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Header, Loading } from '@sharecover-co/ui'
import Aside from '../base/Aside'
import ExitModal from '../modals/ExitModal'

const FlowLayout = ({ progress, title, menu, loading, children }) => (
    <Box>
        <Header progress={progress} title={title} menu={menu} />
        {loading && (
            <Flex
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bg: 'rgba(255, 255, 255, 80%)',
                    color: 'primary'
                }}
            >
                <Loading />
            </Flex>
        )}
        {!loading && (
            <>
                <Flex
                    variant="fixed"
                    sx={{
                        p: [0, 0, 0, 0, 2],
                        justifyContent: 'space-evenly',
                        mb: [140, 0]
                    }}
                >
                    <Box
                        sx={{
                            p: [3, 4],
                            flex: 1,
                            maxWidth: ['auto', 'auto', 'auto', 600]
                        }}
                    >
                        {children}
                    </Box>
                    <Box
                        sx={{
                            display: ['none', 'none', 'block'],
                            p: [3, 4],
                            flex: 1,
                            maxWidth: ['auto', 'auto', 'auto', 600]
                        }}
                    >
                        <Aside />
                        <Box sx={{ p: [2, 3, 4], mt: 1 }}>
                            Please ensure all claims are submitted within 30
                            days from the date of incident otherwise we reserve
                            the right to refuse any claims submitted after this
                            period.
                        </Box>
                    </Box>
                </Flex>
                <Box
                    sx={{
                        display: ['block', 'block', 'none'],
                        position: 'fixed',
                        overflow: 'hidden',
                        top: 'calc(100vh - 140px)',
                        left: 0,
                        right: 0,
                        mx: 1
                    }}
                >
                    <Aside />
                </Box>
            </>
        )}
    </Box>
)

FlowLayout.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    progress: PropTypes.number,
    menu: PropTypes.node,
    loading: PropTypes.bool
}

FlowLayout.defaultProps = {
    children: null,
    title: '',
    progress: 0,
    menu: <ExitModal />,
    loading: false
}

export default FlowLayout
