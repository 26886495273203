import * as actions from '../actions'

const INITIAL_STATE = {}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.NEW_SESSION:
            return { ...INITIAL_STATE }

        case actions.UPLOAD_REQUEST:
            return {
                ...state,
                [action.id]: {
                    id: action.id,
                    key: action.key,
                    file: action.file,
                    progress: 0,
                    status: action.type
                }
            }

        case actions.UPLOAD_PROGRESS:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    progress: action.progress
                }
            }

        case actions.UPLOAD_SUCCESS:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    progress: 100,
                    status: action.type
                }
            }

        case actions.UPLOAD_FAILED:
            return {
                ...state,
                [action.id]: { ...state[action.id], status: action.type }
            }

        case actions.DELETE_UPLOAD_SUCCESS:
        case actions.DELETE_UPLOAD_FAILED:
            const { [action.id]: deleting, ...rest } = state

            return rest

        default:
            return state
    }
}

export default reducer
