import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Field, Submit, Select } from '@sharecover-co/ui'
import * as Yup from 'yup'
import { Form } from 'formik'
import FormManager from './FormManager'
import { connect } from 'react-redux'
import get from 'lodash.get'

const PoliceForm = (props) => {
    const schema = Yup.object().shape({
        policeNumber: Yup.string(),
        registeredForGST: Yup.string().when([], {
            is: () =>
                props.productId ===
                process.env.REACT_APP_SHORT_HOMECOVER_PRODUCT_ID,
            then: Yup.string().required('required'),
            otherwise: Yup.string().notRequired()
        }),
        insuredITCPercentage: Yup.string().when('registeredForGST', {
            is: 'Yes',
            then: Yup.string().required('required')
        }),
        companyABN: Yup.string()
            .min(11, 'Must be exactly 11 digits')
            .max(11, 'Must be exactly 11 digits')
    })
    return (
        <FormManager
            useSession
            validationSchema={schema}
            initialValues={{
                policeNumber: '',
                registeredForGST: '',
                insuredITCPercentage: '',
                companyABN: ''
            }}
            initialTouched={{
                companyABN: true
            }}
            {...props}
        >
            {({ status: { error }, values, handleChange }) => (
                <Form>
                    {error && <Alert>{error}</Alert>}
                    <Field
                        id="policeNumber"
                        name="policeNumber"
                        type="policeNumber"
                        label="Police Number"
                    />
                    {props.productId ===
                        process.env.REACT_APP_SHORT_HOMECOVER_PRODUCT_ID && (
                        <>
                            <Select
                                id="registeredForGST"
                                name="registeredForGST"
                                label="Are you registered for GST?"
                                required
                                choices={{
                                    Yes: 'Yes',
                                    No: 'No'
                                }}
                            />
                            {values.registeredForGST === 'Yes' && (
                                <>
                                    <Select
                                        id="insuredITCPercentage"
                                        name="insuredITCPercentage"
                                        label="Insured ITC %"
                                        required
                                        choices={Object.fromEntries(
                                            [...Array(100).keys()].map((i) => [
                                                ++i,
                                                i
                                            ])
                                        )}
                                    />
                                    <Field
                                        name="companyABN"
                                        label="Company ABN"
                                        type="number"
                                        onChange={(e) => {
                                            if (e.target.value.length <= 11)
                                                handleChange(e)
                                        }}
                                    />
                                </>
                            )}
                        </>
                    )}
                    <Submit label="Next" />
                </Form>
            )}
        </FormManager>
    )
}

PoliceForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    productId: get(state, 'product.product.id', null)
})

export default connect(mapStateToProps)(PoliceForm)
