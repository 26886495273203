import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Alert, Field, Button, Submit, Heading } from '@sharecover-co/ui'
import * as Yup from 'yup'
import { Form, FieldArray } from 'formik'
import FormManager from './FormManager'
import Address from '../../utils/Address'
const schema = Yup.object().shape({
    parties: Yup.array().of(
        Yup.object().shape({
            firstName: Yup.string().required('Required!'),
            lastName: Yup.string().required('Required!'),
            address: Yup.object().shape({
                formatted: Yup.string().required('Required!'),
                streetAddress: Yup.string().required('Required!'),
                city: Yup.string().required('Required!'),
                country: Yup.string().required('Required!')
            }),
            email: Yup.string()
                .email('Please enter a valid email address.')
                .required('Required!'),
            phone: Yup.string().required('Required!')
        })
    )
})

const defaultValues = {
    firstName: '',
    lastName: '',
    address: {
        formatted: '',
        isManualAddress: '',
        suiteAddress: '',
        streetAddress: '',
        town: '',
        city: '',
        region: '',
        postcode: '',
        country: 'AU',
        placeId: ''
    },
    phone: '',
    email: ''
}

const PartiesForm = (props) => (
    <FormManager
        useSession
        validationSchema={schema}
        initialValues={{
            parties: []
        }}
        {...props}
    >
        {({ values, status: { error }, dirty, errors }) => (
            <Form>
                {error && <Alert>{error}</Alert>}
                <FieldArray
                    name="parties"
                    render={(arrayHelpers) => (
                        <div>
                            {values.parties &&
                                values.parties.length > 0 &&
                                values.parties.map((party, index) => (
                                    <div key={index}>
                                        <Flex
                                            sx={{
                                                mt: 4,
                                                mb: -1,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Heading
                                                as="h3"
                                                sx={{ flex: 1, fontSize: 28 }}
                                            >
                                                Person #{index + 1}
                                            </Heading>

                                            <Button
                                                type="button"
                                                onClick={() =>
                                                    arrayHelpers.remove(index)
                                                }
                                                variant="buttons.text"
                                                sx={{ my: 0 }}
                                            >
                                                Remove this person
                                            </Button>
                                        </Flex>
                                        <Field
                                            name={`parties[${index}].firstName`}
                                            type="firstName"
                                            label="First Name"
                                            placeholder="John"
                                            required
                                        />
                                        <Field
                                            name={`parties[${index}].lastName`}
                                            type="lastName"
                                            label="Last Name"
                                            placeholder="Smith"
                                            required
                                        />
                                        <Address
                                            name={`parties[${index}].address.formatted`}
                                            label="Address"
                                            required
                                        />
                                        <Field
                                            name={`parties[${index}].phone`}
                                            type="phone"
                                            label="Phone"
                                            placeholder="+61..."
                                            required
                                        />
                                        <Field
                                            name={`parties[${index}].email`}
                                            type="email"
                                            label="Email Address"
                                            placeholder="contact@sharecover.com"
                                            required
                                        />
                                    </div>
                                ))}
                            <Button
                                type="button"
                                onClick={() => arrayHelpers.push(defaultValues)}
                                variant="buttons.outline"
                                sx={{ my: 2 }}
                            >
                                {/* show this when user has removed all parties from the list */}
                                Add another person
                            </Button>
                        </div>
                    )}
                />
                {dirty && typeof errors.parties === 'string' ? (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>
                        {errors.parties}
                    </div>
                ) : null}

                <Submit label="Next" />
            </Form>
        )}
    </FormManager>
)

PartiesForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default PartiesForm
