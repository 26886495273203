import React from 'react'
import { connect } from 'react-redux'
import { Box, Heading, Link } from '@sharecover-co/ui'
import { useHistory } from 'react-router-dom'
import FlowLayout from '../layouts/FlowLayout'
import DateOccurredForm from '../forms/DateOccurredForm'
import { addData } from '../../actions/session'

const DateOccurred = ({ addData }) => {
    const history = useHistory()

    return (
        <FlowLayout title="New Claim" progress={15}>
            <Heading sx={{ fontSize: 2 }}>When did the incident occur?</Heading>
            <DateOccurredForm
                onSubmit={async (values) => {
                    await addData(values)

                    history.push('/location')
                }}
            />
            <Box sx={{ my: 3 }}>
                <Link to="/type" sx={{ variant: 'links.buttonOutline' }}>
                    Back
                </Link>
            </Box>
        </FlowLayout>
    )
}

const mapDispatchToProps = {
    addData
}

export default connect(null, mapDispatchToProps)(DateOccurred)
